import {
  AspectRatio, Box, Flex, Image, Text
} from "@chakra-ui/react";
import { Gallery } from './Gallery';


const HouseCard = ({ houseData }) => {

  // Prepare images for the Gallery, limited to the first 10 images, with first image coming as thumbnail
  const galleryImages = [
    ...(houseData.thumbnail ? [{
      src: houseData.thumbnail,
      alt: `Thumbnail of Del Mar #${houseData.houseNumber}`
    }] : []),
    ...houseData.pictures.slice(1, 10).map((pic, index) => ({
      src: pic.original,
      alt: `Photo of Del Mar #${houseData.houseNumber + index + 1}`
    }))
  ];


  return (
    <Flex
      className='listing-card' flexDirection={{ base: 'column', md: 'row' }} m={2}
      position='relative' gap={{ base: 0, md: 4 }} w={'100%'} justifyContent={'center'}
      alignContent={'center'} alignItems={'center'}
    >

      <Box w={'100%'} maxW={{ base: '300px', md: '300px' }}>
        {houseData.thumbnail || houseData.pictures[0]?.original ?
          <Gallery images={galleryImages} aspectRatio={1.2} />
          :
          <AspectRatio width="100%" ratio={1.2}>
            <Image
              src={'media/comingsoon.jpg'}
              alt={'Photo of Del Mar #' + houseData.houseNumber}
              objectFit="cover"
              width="100%"
              height="100%"
              className='listing-rating-star'
            />
          </AspectRatio>
        }
      </Box>

      <Box w={'100%'} maxW={{ base: '300px', md: '300px' }}>
        {houseData.houseNumber && houseData.name &&
          <Flex className='listing-house-name' fontSize={'1em'} textAlign={'left'} color={"dmNavy.400"} mt={{ base: 3, md: 0 }} px={1} fontWeight={700}>
            #{houseData.houseNumber} - {houseData.name}
          </Flex>
        }

        {houseData.title &&
          <Flex className='listing-description' fontSize={'1em'} textAlign={'left'} color={"dmNavy.400"} mt={1} px={1} fontWeight={400}>
            {houseData.title}
          </Flex>
        }

        {houseData.town &&
          <Flex className='listing-town' fontSize={'1em'} textAlign={'left'} color={"dmNavy.400"} mt={1} px={1}>
            {houseData.town}, MA
          </Flex>
        }

        <Flex className='listing-details' fontSize={'0.9em'} alignItems={'flex-start'} color={"dmNavy.400"} mt={2} px={1}>
          <Flex flexDirection={'column'} className='listing-occupancy' alignItems={'center'} width={"max-content"} me={3}>
            <Image
              src={'../media/occupancy.png'}
              alt="Max Occupancy"
              objectFit={"cover"}
              align={"left"}
              width={'30px'}
              height={'30px'}
              className='listing-occupancy-image'
            />
            <Text className='listing-occupancy-text'>
              {!isNaN(houseData.occupancy) ? houseData.occupancy as number : 0} Guests
            </Text>
          </Flex>
          {houseData.bedrooms &&
            <Flex flexDirection={'column'} className='listing-bedrooms' alignItems={'center'} width={"max-content"} me={3}>
              <Image
                src={'../media/bedrooms.png'}
                alt="Bedrooms"
                objectFit={"cover"}
                align={"left"}
                width={'30px'}
                height={'30px'}
                className='listing-bedrooms-image'
              />
              <Text className='listing-bedrooms-text'>
                {!isNaN(houseData.bedrooms) ? houseData.bedrooms as number : 0} Beds
              </Text>
            </Flex>
          }
          {houseData.bathrooms &&
            <Flex flexDirection={'column'} className='listing-bathrooms' alignItems={'center'} width={"max-content"}>
              <Image
                src={'../media/bathrooms.png'}
                alt="Bathrooms"
                objectFit={"cover"}
                align={"left"}
                width={'30px'}
                height={'30px'}
                className='listing-bathrooms-image'
              />
              <Text className='listing-bathrooms-text'>
                {!isNaN(houseData.bathrooms) ? houseData.bathrooms as number : 0} Baths
              </Text>
            </Flex>
          }
        </Flex>
      </Box>

    </Flex>
  )
}

export { HouseCard };

