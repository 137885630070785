import { CopyIcon, EmailIcon } from '@chakra-ui/icons';
import {
  Button, Link,
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
  SimpleGrid, useClipboard, useToast
} from '@chakra-ui/react';
import React from 'react';
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { setUserShare } from "../helpers/fetch";

// Add any additional icons you need for social media

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  url: string;
  houseNumber: string;
  email: string;
}

const ShareModal: React.FC<ShareModalProps> = ({ isOpen, onClose, url, houseNumber, email }) => {
  const { hasCopied, onCopy } = useClipboard(url);
  const toast = useToast();

  const handleCopy = () => {
    onCopy();
    setShare();
    toast({
      title: 'Link Copied',
      description: 'The link has been copied to your clipboard.',
      status: 'success',
      duration: 4000,
      isClosable: true,
      position: "top"
    });
  };

  const setShare = () => {
    //console.log(houseNumber)
    //console.log(email)
    setUserShare(houseNumber, email)
  }

  const emailSubject = encodeURIComponent('Check out this place');
  const emailBody = encodeURIComponent(`I thought you might like this place: ${url}`);

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent('Check out this place!')}`;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'} id='share-modal'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share this house</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            <Button leftIcon={<CopyIcon />} onClick={handleCopy} w={'100%'} size={'lg'} h={'70px'}>
              {hasCopied ? 'Copied' : 'Copy Link'}
            </Button>
            {/* Email Button */}
            <Link onClick={() => setShare()} href={`mailto:?subject=${emailSubject}&body=${emailBody}`} isExternal w={'100%'}>
              <Button leftIcon={<EmailIcon />} w={'100%'} size={'lg'} h={'70px'}>
                Email
              </Button>
            </Link>
            {/* Facebook Button */}
            <Link onClick={() => setShare()} href={facebookShareUrl} isExternal w={'100%'}>
              <Button leftIcon={<FaFacebook />} w={'100%'} size={'lg'} h={'70px'}>
                Facebook
              </Button>
            </Link>
            {/* Twitter Button */}
            <Link onClick={() => setShare()} href={twitterShareUrl} isExternal w={'100%'}>
              <Button leftIcon={<FaTwitter />} w={'100%'} size={'lg'} h={'70px'}>
                Twitter
              </Button>
            </Link>
            {/* You can add more buttons here if needed */}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <Button variant={'outline'} colorScheme={'dmNavy'} onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
