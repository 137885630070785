import { CloseIcon } from '@chakra-ui/icons';
import {
  Box, Button, Flex, HStack, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, useToast
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { createReferral } from '../helpers/fetch';

interface ReferralModalProps {
  isOpen: boolean;
  onClose: () => void;
  referrer: { firstname: string; lastname: string; email: string };
}

const ReferralModal: React.FC<ReferralModalProps> = ({ isOpen, onClose, referrer }) => {
  const toast = useToast();
  const [referrals, setReferrals] = useState([{ firstname: '', lastname: '', email: '' }]);
  const [errors, setErrors] = useState<{ firstname: boolean[]; lastname: boolean[]; email: string[] }>({
    firstname: [],
    lastname: [],
    email: []
  });
  const [touched, setTouched] = useState<{ firstname: boolean[]; lastname: boolean[]; email: boolean[] }>({
    firstname: [],
    lastname: [],
    email: []
  });
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const isValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleChange = (index: number, field: keyof typeof referrals[0], value: string) => {
    const newReferrals = [...referrals];
    newReferrals[index] = { ...newReferrals[index], [field]: value };
    setReferrals(newReferrals);

    // Check if the last row is complete and add a new empty row
    if (
      index === referrals.length - 1 &&
      newReferrals[index].firstname &&
      newReferrals[index].lastname &&
      isValidEmail(newReferrals[index].email)
    ) {
      setReferrals([...newReferrals, { firstname: '', lastname: '', email: '' }]);
    }
  };

  const handleBlur = (index: number, field: keyof typeof referrals[0]) => {
    setTouched((prev) => ({
      ...prev,
      [field]: { ...prev[field], [index]: true }
    }));

    validateField(index, field);
  };

  const validateField = (index: number, field: keyof typeof referrals[0]) => {
    setErrors((prev) => {
      const newErrors = { ...prev };
      if (field === 'email') {
        newErrors.email[index] = isValidEmail(referrals[index].email) ? '' : 'Please enter a valid email';
      } else {
        newErrors[field][index] = referrals[index][field] === '';
      }
      return newErrors;
    });
  };

  const handleSubmit = async () => {
    const validReferrals = referrals.filter(r => r.firstname && r.lastname && isValidEmail(r.email));

    setSubmissionStatus("loading")

    if (validReferrals.length === 0) {
      setSubmissionStatus("error")
    }

    try {
      await createReferral({ referrer, referrals: validReferrals });
      setSubmissionStatus("success")
      setReferrals([{ firstname: '', lastname: '', email: '' }]);
      setErrors({ firstname: [], lastname: [], email: [] });
      setTouched({ firstname: [], lastname: [], email: [] });
    } catch (error) {
      setSubmissionStatus("error")
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => { setSubmissionStatus(null); onClose(); }} size={{ base: "full", md: "xl" }}>
      <ModalOverlay />
      {submissionStatus === "success" ? (
        <ModalContent borderRadius="lg" p={3}>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody textAlign="center">
            <Text fontSize="2xl" color="dmOrange.500" fontWeight="bold" my={2}>
              Success!
            </Text>
            <Text fontSize="md" mb={6}>
              We've sent you an email with more details!
            </Text>
          </ModalBody>

          <ModalFooter flexDirection="column">
            <Button
              bg="dmOrange.500"
              className="dm-button-orange-pill"
              color="white"
              width="full"
              size="lg"
              borderRadius="full"
              onClick={() => { setSubmissionStatus(null); onClose(); }}
            >
              Continue
            </Button>

            {/* Illustration Placeholder */}
            <Box mt={6} textAlign="center">
              <img src="/media/referral.png" alt="Refer a Friend to Del Mar Vacations" width="100%" />
            </Box>
          </ModalFooter>
        </ModalContent>
      ) : submissionStatus === "loading" ? (
        <ModalContent borderRadius="lg" p={3}>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody textAlign="center">
            <Spinner mt={12} />
          </ModalBody>
          <ModalFooter flexDirection="column">
            <Box mt={6} textAlign="center">
              <img src="/media/referral.png" alt="Refer a Friend to Del Mar Vacations" width="100%" />
            </Box>
          </ModalFooter>
        </ModalContent>
      ) : (
        <ModalContent borderRadius="lg" p={3}>
          {/* Close Button */}
          <HStack justify="flex-end">
            <Flex border="solid 1px" alignItems={"center"} borderRadius={16}>
              <IconButton
                icon={<CloseIcon />}
                aria-label="Close modal"
                variant="ghost"
                onClick={(onClose)}
                size="sm"
              />
              <Text cursor="pointer" fontSize="sm" onClick={onClose} pr={2}>
                I don’t want $100
              </Text>
            </Flex>
          </HStack>

          <ModalHeader mt={6} textAlign="center" fontSize="2xl" textDecoration={"underline"} textDecorationColor={"dmOrange.500"}>
            <Text as="span" fontWeight="bold">Give $100</Text>,
            <Text as="span"> Get $100, </Text>
            <Text as="span" color="dmOrange.500" fontWeight="bold">Giveaway.</Text>
          </ModalHeader>

          <ModalBody textAlign="center">
            <Text fontSize="md" mb={4}>
              Refer friends below, and when they book,<br />we’ll give you both $100.
            </Text>
            <Text fontSize="md" mb={8}>
              Plus, you’ll automatically be entered to win a free stay. <br />
              <Text as="span" fontWeight="bold">It’s that easy.</Text>
            </Text>

            {/* Form Fields */}
            <Box width="100%" textAlign="left">
              <HStack spacing={2} fontWeight="500" fontSize="md" mb={2}>
                <Text width="25%">First Name</Text>
                <Text width="25%">Last Name</Text>
                <Text width="50%">Email</Text>
              </HStack>

              {referrals.map((referral, index) => (
                <HStack key={index} spacing={2} mb={2}>
                  <Input
                    placeholder="First name"
                    width="25%"
                    value={referral.firstname}
                    onChange={(e) => handleChange(index, 'firstname', e.target.value)}
                    onBlur={() => handleBlur(index, 'firstname')}
                    borderColor={touched.firstname[index] && errors.firstname[index] ? "red.500" : "gray.200"}
                  />
                  <Input
                    placeholder="Last name"
                    width="25%"
                    value={referral.lastname}
                    onChange={(e) => handleChange(index, 'lastname', e.target.value)}
                    onBlur={() => handleBlur(index, 'lastname')}
                    borderColor={touched.lastname[index] && errors.lastname[index] ? "red.500" : "gray.200"}
                  />
                  <Input
                    type="email"
                    width="50%"
                    placeholder="Email"
                    value={referral.email}
                    onChange={(e) => handleChange(index, 'email', e.target.value)}
                    onBlur={() => handleBlur(index, 'email')}
                    borderColor={touched.email[index] && errors.email[index] ? "red.500" : "gray.200"}
                  />
                </HStack>
              ))}
            </Box>
          </ModalBody>

          <ModalFooter flexDirection="column">
            <Button
              bg="dmOrange.500"
              className="dm-button-orange-pill"
              color="white"
              width="full"
              size="lg"
              borderRadius="full"
              onClick={handleSubmit}
              isDisabled={referrals.slice(0, -1).some(r => !r.firstname || !r.lastname || !isValidEmail(r.email)) || referrals.length <= 1}
            >
              Refer my friends!
            </Button>

            <Box mt={6} textAlign="center">
              <img src="/media/referral.png" alt="Refer a Friend to Del Mar Vacations" width="100%" />
            </Box>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal >
  );
};

export default ReferralModal;
