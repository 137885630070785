import {
  AspectRatio,
  Button,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { useParams } from "react-router-dom";
import { SearchResultsContext } from "../../../contexts/SearchResultsContext";
import { FullScreenImageView } from "../../display/ListingHelpers";
import ShareModal from "../../ShareModal";

import { useNavigate } from "react-router-dom";

export const PhotoGallery = () => {
  const navigate = useNavigate();
  let { houseNumber } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isFullScreenOpen, setIsFullScreenOpen] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const {
    isOpen: isPhotoModalOpen,
    onOpen: onOpenPhotoModal,
    onClose: onClosePhotoModal,
  } = useDisclosure();

  const openFullScreenView = (image) => {
    if (!isMobile) {
      setSelectedImage(image);
      setIsFullScreenOpen(true);
    }
  };

  const { isOpen: isShareModalOpen, onClose: onCloseShareModal } =
    useDisclosure({
      id: "share-modal",
    });

  const { houseData, userEmail } = useContext(SearchResultsContext);

  const closeFullScreenView = () => {
    setIsFullScreenOpen(false);
    setSelectedImage(null);
  };

  const returnToHome = async () => {
    navigate(`/`);
  };

  const navigateImage = (direction) => {
    const currentIndex = houseData.pictures.findIndex(
      (p) => p.original === selectedImage.original
    );
    const nextIndex =
      (currentIndex + direction + houseData.pictures.length) %
      houseData.pictures.length;
    setSelectedImage(houseData.pictures[nextIndex]);
  };

  return (
    <>
      {/* Photo Gallery */}
      <Flex
        maxWidth="1440px"
        width={"100%"}
        px={{ base: 0, md: 2 }}
        pb={{ base: 8, md: 2 }}
        pt={{ base: 0, md: 2 }}
        textAlign={"left"}
        alignItems={"start"}
        flexDirection={"column"}
      >
        <Grid
          h={{ base: "70vw", sm: "55vw", md: "40vw" }}
          maxH={"450px"}
          w={"100%"}
          templateRows={{ base: "repeat(1,1fr)", md: "repeat(2, 1fr)" }}
          templateColumns={{ base: "repeat(1,1fr)", md: "repeat(4, 1fr)" }}
          gap={1.5}
          onClick={onOpenPhotoModal}
          sx={{ "&:hover": { cursor: "pointer" } }}
        >
          <GridItem
            rowSpan={{ base: 1, md: 2 }}
            colSpan={{ base: 1, md: 2 }}
            overflow="hidden"
          >
            <Image
              src={
                houseData.pictures && houseData.pictures.length > 0
                  ? houseData.pictures[0].original
                  : "media/comingsoon.jpg"
              }
              alt="Listing Image 1"
              height="100%"
              width="100%"
              objectFit="cover"
              borderStartRadius={{ base: 0, md: 8 }}
              borderEndRadius={{ base: 0, md: 0 }}
            />
          </GridItem>
          {houseData.pictures &&
            houseData.pictures.length > 1 &&
            houseData.pictures.slice(1, 5).map((picture, index) => (
              <GridItem
                key={`thumbnail-${index}`}
                colSpan={1}
                overflow="hidden"
                display={{ base: "none", md: "block" }}
              >
                <Image
                  src={
                    picture.original ||
                    "media/comingsoon.jpg"
                  }
                  alt={`Listing Image ${index + 2}`}
                  height="100%"
                  width="100%"
                  objectFit="cover"
                  borderRadius={
                    index === 1
                      ? "0 8px 0 0"
                      : index === 3
                        ? "0 0 8px 0"
                        : "none"
                  }
                />
              </GridItem>
            ))}
        </Grid>
        <Button
          mt={{ base: -12, md: -14 }}
          mx={4}
          colorScheme="dmNavy"
          variant="outline"
          bgColor="white"
          onClick={onOpenPhotoModal}
          alignSelf={{ base: "end", md: "start" }}
          size={{ base: "sm", md: "md" }}
          boxShadow={"dmShadow"}
        >
          See all {houseData.pictures.length}
          <Text as="span">&nbsp;photos</Text>
        </Button>
        <Link
          onClick={returnToHome}
          mb={4}
          position={"absolute"}
          display={{ base: "block", md: "none" }}
        >
          <IconButton
            aria-label={"Return to Home"}
            borderRadius={"50%"}
            bgColor={"white"}
            border="solid 1px"
            borderColor={"dmNavy"}
            m={2}
            icon={<MdKeyboardBackspace />}
            boxShadow={"dmShadow"}
          />
        </Link>

        <ShareModal
          isOpen={isShareModalOpen}
          onClose={onCloseShareModal}
          url={window.location.href}
          houseNumber={houseNumber}
          email={userEmail}
        />

        <Modal
          isOpen={isPhotoModalOpen}
          onClose={onClosePhotoModal}
          size="full"
        >
          <ModalOverlay />

          <ModalContent position="relative">
            <ModalHeader
              position="sticky"
              top={0}
              zIndex={"500"}
              bgColor="white"
              pb={8}
            >
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody mt={0} mb={6} textAlign={"center"}>
              {/* Display all photos in a scrollable view */}
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  sm: "repeat(auto-fill, minmax(375px, 1fr))",
                  md: "repeat(auto-fill, minmax(500px, 1fr))",
                }}
                gap={4}
              >
                {houseData.pictures &&
                  houseData.pictures.map((picture, index) => (
                    <AspectRatio
                      ratio={1.2}
                      onClick={() => openFullScreenView(picture)}
                      key={`modal-${index}-${picture.original}`}
                    >
                      <Image
                        key={`modal-${index}-${picture.original}`}
                        src={picture.original}
                        alt={`Listing Image ${index + 1}`}
                        objectFit="contain"
                        onClick={() => openFullScreenView(picture)}
                        sx={{ "&:hover": { cursor: "pointer" } }}
                      />
                    </AspectRatio>
                  ))}
              </Grid>
              {isFullScreenOpen && (
                <FullScreenImageView
                  image={selectedImage}
                  onClose={closeFullScreenView}
                  onNext={() => navigateImage(1)}
                  onPrevious={() => navigateImage(-1)}
                />
              )}
              <Button
                mt={6}
                colorScheme="dmNavy"
                variant="outline"
                onClick={onClosePhotoModal}
              >
                Back to listing
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
      {/* End Photo Gallery */}
    </>
  );
};
