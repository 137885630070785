export const MIN_GUESTS = 1; // Minimum number of guests
export const MAX_GUESTS = 23; // Maximum number of guests

export const TOWNS = [
  "Barnstable",
  "Bourne",
  "Brewster",
  "Chatham",
  "Dennis",
  "Eastham",
  "Falmouth",
  "Harwich",
  "Mashpee",
  "Orleans",
  "Provincetown",
  "Sandwich",
  "Truro",
  "Wellfleet",
  "Yarmouth",
];

export const MAX_END_DATE = "2026-12-31";
